// $body-bg: #cccccc;
// $body-color: #999999;
$font-family-sans-serif: "Poppins", sans-serif;
$utilities: (
  "custom-margin-top": (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
    ),
  ),
);
$font-size-base: 1rem;

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

// Common

body {
  width: 100%;
  position: relative;
}

p.main-form-text {
  font-weight: 500;
  color: #494949;
}

h3 {
  letter-spacing: 10px;
  font-weight: bold;
}

.display-none {
  display: none;
}

div.header-placeholder {
  height: 60px;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

// Product

.prod-grid {
  margin-bottom: 8px;
  width: 250px;

  span.prod-sku {
    font-weight: 300;
  }

  img {
    cursor: pointer;
  }

  &:hover {
    img {
      opacity: 80%;
    }
  }
}

// Header

header {
  nav.navbar {
    a.navbar-brand {
      img.nav-logo {
        height: 38px;
        width: auto;
        vertical-align: middle !important;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}

// Breadcrumb

section#breadcrumb {
  background: rgb(33, 37, 41);
  font-size: 0.9rem;
  .breadcrumb {
    padding-top: 10px;
    padding-bottom: 10px;
    li {
      &,
      & > a {
        color: #ffffffd8;
      }
    }
  }
}

// Footer

footer {
  background: #000;

  img.footer-logo {
    height: 128px;
    width: auto;
    vertical-align: middle !important;
    display: inline-block;
    margin-right: 10px;
  }

  .footer-site-title {
    display: inline-block;
    margin-left: 18px;
  }

  .footer-list {
    padding-left: 5px;
  }

  .footer-list,
  .footer-text {
    font-size: 0.9rem;
  }

  .footer-list-heading {
    font-size: 1rem;
  }

  .footer-list {
    .list-group-item {
      background: transparent;
      color: #fff;
      padding-bottom: 4px;
      padding-left: 5px;
      padding-top: 4px;

      &::before {
        content: "»";
        // font-size: 1rem;
        padding-right: 10px;
      }

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          color: #76c8d1;
        }
      }

      svg {
        vertical-align: inherit;
      }
    }
  }
}

// Section Home

section#home {
}

// Section Catalog

section#catalog {
  div.prod-filter {
    div.card-body {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
    button {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

// Section About Us

section#about-us {
}

// Section Info Box

section#info-box {
  background: #f5efe7;
}
